import React, {useState} from 'react';
import {graphql} from "gatsby";
import Heading from "../ui/heading";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import CarouselAboutOffice from '../components/Carousel/CarouselAboutOffice';
import MapBox from '../components/MapBox';
import FormBlock from "../components/Form";
import {ContainerCarousel, ContainerL, ContainerMap} from '../ui/containers';
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";
import styled from "styled-components";

const Otstup = styled.div`
  margin-top: 80px;
  @media (max-width: 568px) {
    margin-top: 0px;
  }
`;

const Showroom = ({data}) => {
    const showroomData = data.allStrapiShowroom.nodes[0];
    const contactsData = data.allStrapiContacts.nodes[0];
    const visible = data.allStrapiOption.nodes[0].ShowroomVisible;
    const [openModal, setOpenModal] = useState(false);
    const Marquiz = typeof window !== "undefined" && window.Marquiz;
    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }

    if (visible === false) {
        return (
            <>
                <Seo title="404: Not found"/>
                <h1 style={{color: "white"}}>404: Not Found</h1>
                <p>You just hit a route that doesn t exist... the sadness.</p>
            </>
        )
    }

    return (
        <div>
            <Seo title={showroomData.seo_title}
                 description={showroomData.seo_description}
                 keywords={showroomData.seo_keywords.split(',')}
            />

            <>
                <Header/>
                <Modal openModal={openModal} toggleOpenModal={toggleOpenModal}/>
                <Otstup/>
                <ContainerL style={{margin: "0 auto 100px auto"}}>
                    <Heading
                        title={"ШОУ"}
                        boldTitle={"РУМ"}
                        description={showroomData.description}
                        button
                        toggleOpenModal={() => Marquiz.showModal('60dc9d431568cf003e72e6ca')}
                        buttonText="УЗНАТЬ СТОИМОСТЬ"
                        background="ЖДЕМ ТЕБЯ"
                    />
                </ContainerL>

                <ContainerCarousel>
                    <CarouselAboutOffice
                        contacts={contactsData}
                        items={showroomData.carousel}
                    />
                </ContainerCarousel>

                <ContainerMap style={{margin: "120px auto"}}>
                    <MapBox/>
                </ContainerMap>

                <ContainerL style={{margin: "120px auto"}}>
                    <FormBlock/>
                </ContainerL>

                <Footer toggleOpenModal={toggleOpenModal}/>
            </>
        </div>
    );
};

export default Showroom;

export const query = graphql`
    query ShowroomQuery {
        allStrapiShowroom {
            nodes {
                seo_description
                seo_keywords
                seo_title
                description
                carousel {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allStrapiOption {
            nodes {
                ShowroomVisible
            }
        }
        allStrapiContacts {
            nodes {
                email
                adress
                phone
                schedule
            }
        }
    }

`
